<template>
  <div class="container">
    <div class="vacancies__title title">Вакансии</div>
    <div class="vacancies">
      <div class="vacancies-row" v-for="group in vacancies" :key="group.id">
        <div class="vacancies__department">{{ group.name }}</div>

        <div class="vacancy" v-for="vacancy in group.items" :key="vacancy.id">
          <Accordeon :item="vacancy" :classItem="'accordeon--vacancy'">
            <div class="typography" v-html="vacancy.text"></div>
            <button
              class="btn"
              @click.prevent="$router.push('/vacancies/resume')"
            >
              Отправить резюме
            </button>
          </Accordeon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordeon from "@/components/cards/accordeon";
import store from "@/store/index.js";

export default {
  components: {
    Accordeon,
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch("VACANCIES_FETCH_DATA").then(() => {
      next();
    });
  },

  computed: {
    vacancies() {
      return this.$store.state.vacancies.vacancies;
    },
  },
};
</script>
